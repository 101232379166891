import React, { useEffect, useRef } from 'react';

import Loader from 'components/Loader';

import styles from './CardModel.module.scss';

const CARD_WIDTH = 293;
const CARD_HEIGHT = 184;
const CANVAS_SCALE = 4;

export enum Color {
  BlackGold = 'Black_Gold',
  DarkPurpleSilver = 'DarkPurple_Silver',
  PurpleWhite = 'Purple_White',
}

const TEXT_COLORS: Record<Color, string> = {
  [Color.BlackGold]: '#E0CA91',
  [Color.DarkPurpleSilver]: '#D8D8D8',
  [Color.PurpleWhite]: '#FFFFFF',
};

const EDGE_COLORS: Record<Color, string> = {
  [Color.BlackGold]: '#000000',
  [Color.DarkPurpleSilver]: '#422DB1',
  [Color.PurpleWhite]: '#b8a9f4',
};

export enum Group {
  Nurse = 'Nurse',
  Physician = 'Physician',
  Healthcare = 'Healthcare',
}

enum Orientation {
  Front = '0deg 75deg 105%',
  Back = '180deg 105deg 105%',
}

interface CardModelProps {
  text: string;
  color: Color;
  group: Group;
}

const CardModel = ({ text, color, group }: CardModelProps) => {
  const modelRef = useRef<ModelViewer.HTMLModelViewerElement>(null);
  const frontImageRef = useRef<HTMLImageElement>(null);
  const backImageRef = useRef<HTMLImageElement>(null);

  const modelPath = 'card.gltf';

  const createCanvasTexture = (
    render: (context: CanvasRenderingContext2D) => void,
    width = CARD_WIDTH * CANVAS_SCALE,
    height = CARD_HEIGHT * CANVAS_SCALE,
  ) => {
    const canvasTexture = modelRef.current!.createCanvasTexture();
    const canvas: HTMLCanvasElement = canvasTexture.source.element;
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d')!;
    render(context);
    canvasTexture.source.update();
    return canvasTexture;
  };

  const updateFrontImage = () => {
    const model = modelRef.current?.model;
    if (model && frontImageRef.current && frontImageRef.current.complete) {
      const { baseColorTexture } = model.materials[0].pbrMetallicRoughness;
      baseColorTexture.setTexture(
        createCanvasTexture((context) => {
          context.translate(0, context.canvas.height);
          context.scale(1, -1);

          context.drawImage(frontImageRef.current!, 0, 0, context.canvas.width, context.canvas.height);

          const spaces = `${String.fromCharCode(8202)}${String.fromCharCode(8202)}`;
          const transformedText = text.toUpperCase().split('').join(spaces);
          const fontSize = 12;
          context.font = `${fontSize * CANVAS_SCALE}px/160% Poppins`;
          context.fillStyle = TEXT_COLORS[color];
          context.fillText(transformedText, 20 * CANVAS_SCALE, (145 + fontSize) * CANVAS_SCALE);
        }),
      );
    }
  };

  const updateBackImage = () => {
    const model = modelRef.current?.model;
    if (model && backImageRef.current && backImageRef.current.complete) {
      const { baseColorTexture } = model.materials[1].pbrMetallicRoughness;
      baseColorTexture.setTexture(
        createCanvasTexture((context) => {
          context.translate(context.canvas.width, context.canvas.height);
          context.scale(-1, -1);

          context.drawImage(backImageRef.current!, 0, 0, context.canvas.width, context.canvas.height);

          const fontSize = 10;
          context.font = `${fontSize * CANVAS_SCALE}px/160% Poppins`;
          context.fillStyle = TEXT_COLORS[color];
          context.fillText(text.toUpperCase(), 20 * CANVAS_SCALE, (109 + fontSize) * CANVAS_SCALE);
        }),
      );
    }
  };

  const updateEdge = () => {
    const model = modelRef.current?.model;
    if (model) {
      const { baseColorTexture } = model.materials[2].pbrMetallicRoughness;
      baseColorTexture.setTexture(
        createCanvasTexture(
          (context) => {
            context.fillStyle = EDGE_COLORS[color];
            context.fillRect(0, 0, 1, 1);
          },
          1,
          1,
        ),
      );
    }
  };

  const updateImages = () => {
    updateFrontImage();
    updateBackImage();
    updateEdge();
  };

  useEffect(() => {
    if (modelRef.current) {
      modelRef.current.addEventListener(
        'load',
        () => {
          updateImages();
          modelRef.current!.addEventListener('click', (event: MouseEvent) => {
            if (modelRef.current) {
              const modelViewer = modelRef.current;
              const material = modelViewer.materialFromPoint(event.clientX, event.clientY);

              modelViewer.interpolationDecay = 200;
              setTimeout(() => {
                modelViewer.interpolationDecay = 50;
              }, 500);

              if (material?.name === 'Back') {
                if (modelViewer.cameraOrbit !== Orientation.Back) {
                  modelViewer.cameraOrbit = Orientation.Back;
                } else {
                  modelViewer.cameraOrbit = Orientation.Front;
                }
              } else if (modelViewer.cameraOrbit !== Orientation.Front) {
                modelViewer.cameraOrbit = Orientation.Front;
              } else {
                modelViewer.cameraOrbit = Orientation.Back;
              }
            }
          });
        },
        { once: true },
      );
      updateImages();
    }
  }, [text, color]);

  return (
    <div className={styles.modelViewer}>
      <img
        src={`/card-front-images/Color=${color}, Group=${group}.svg`}
        alt=""
        ref={frontImageRef}
        className={styles.hidden}
        onLoad={updateFrontImage}
      />
      <img
        src={`/card-back-images/Color=${color}.svg`}
        alt=""
        ref={backImageRef}
        className={styles.hidden}
        onLoad={updateBackImage}
      />
      <model-viewer
        src={modelPath}
        ref={modelRef}
        camera-controls
        touch-action="rotate"
        disable-pan
        disable-tap
        orientation={Orientation.Front}
      >
        <Loader color="#ffffff80" slot="poster" className={styles.loader} size={100} />
      </model-viewer>
    </div>
  );
};

export default CardModel;
